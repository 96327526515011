import { Checkbox, Tooltip } from 'antd/lib'
import styles from './CameraList.module.css'
import { IconNames } from 'components/UI/Icon/icons'
import { DeviceDTO } from 'api/api_code'
import Icon from '@ant-design/icons'
import useTranslation from 'next-translate/useTranslation'
import { Dispatch, SetStateAction } from 'react'

type CameraListFooterProps = {
  selectedRowKeys: string[]
  handleSelectAll: () => void
  handleUpgrade: () => void
  disableUpgrade: boolean
  handleSelectedKeys: Dispatch<SetStateAction<string[]>>
  data: DeviceDTO[]
}

const CameraListFooter = ({
  selectedRowKeys,
  handleSelectAll,
  handleUpgrade,
  disableUpgrade,
  handleSelectedKeys,
  data,
}: CameraListFooterProps) => {
  const { t } = useTranslation('cameras')
  return (
    <div style={{ display: 'flex' }}>
      <Checkbox
        className={styles.tableSelectAll}
        style={{ marginRight: '35px', display: 'flex', alignItems: 'center' }}
        checked={Boolean(selectedRowKeys.length)}
        indeterminate={selectedRowKeys.length > 0 && selectedRowKeys.length < data.length}
        onClick={handleSelectAll}
      >
        {t('tableActions.select')}
      </Checkbox>
      {Boolean(selectedRowKeys.length) && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h4>{t('tableActions.selection', { count: selectedRowKeys.length })}</h4>
          <Tooltip
            title={disableUpgrade ? t('tableActions.upgrade.tooltip') : ''}
            className={`${disableUpgrade ? styles.disabled : ''}`}
          >
            <button
              className={`${styles.tableFooterAction} ml-3`}
              onClick={handleUpgrade}
              disabled={disableUpgrade}
            >
              {t('tableActions.upgrade.button')} <Icon component={IconNames.DOWNLOAD} size={14} />
            </button>
          </Tooltip>
          <button
            className={`${styles.tableFooterAction} ml-2`}
            onClick={() => handleSelectedKeys([])}
          >
            {t('tableActions.clear')} <Icon component={IconNames.CROSS} size={14} />
          </button>
        </div>
      )}
    </div>
  )
}

export default CameraListFooter
